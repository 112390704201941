import { getRequest } from '~/infra/api/request'

export const Store = defineStore('Store', {
  state: () => ({
    showProfile: false,
    viewPortPC: false,
    shopData: {
      vip_boost: 0,
      active_duration: '',
      package: {
        item_type: 0,
        status: 1
      },
      transaction_confirm: 0
    },
    isConfirm: 0,
    activePack: [] as number[],
    expirePacks: [] as number[],
    loadingPack: 0,
    buyPack: [1, 2],
    isExpired: false,
    disabledBuyPack: false
  }),
  getters: {},
  actions: {
    async getDataShop(isInBuyProcess = false) {
      try {
        const resp: any = await getRequest('/shop')
        this.shopData = resp.data?.data
        this.isConfirm = this.shopData?.transaction_confirm
        if (this.shopData?.package?.status === 1) {
          this.isExpired = false
          this.loadingPack = this.shopData?.package?.item_type
          this.isConfirm = 0
          this.activePack = []
          this.expirePacks = []
          if (!isInBuyProcess) {
            this.buyPack = []
          }
        } else if (this.shopData?.package?.status === 2) {
          this.loadingPack = 0
          this.isExpired = false
          this.expirePacks = []
          this.activePack = [this.shopData?.package?.item_type] as number[]
          if (this.shopData.package.item_type === 3) {
            this.activePack = [...this.activePack, 2] as number[]
          }
          if (!isInBuyProcess) {
            this.buyPack = []
          }
        } else if (this.shopData?.package?.status === 4) {
          this.isExpired = true
          this.loadingPack = 0
          this.activePack = [this.shopData?.package?.item_type] as number[]
          this.expirePacks = [this.shopData?.package?.item_type] as number[]
          if (this.shopData.package.item_type === 3) {
            this.expirePacks = [...this.expirePacks, 2] as number[]
          }
          if (!isInBuyProcess) {
            this.buyPack = []
          }
        } else {
          this.isExpired = false
          this.buyPack = [1, 2]
          this.activePack = []
          this.expirePacks = []
          this.isConfirm = 0
          this.loadingPack = 0
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
})
export default Store
